var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "not-found" }, [
    _c("div", { staticClass: "banner" }, [
      _c("img", { attrs: { src: _vm.img, alt: "" } }),
    ]),
    _c("div", { staticClass: "button_btn", on: { click: _vm.link } }, [
      _vm._v("立即跳转"),
    ]),
    _c("p", { staticClass: "titel" }, [
      _vm._v(
        "\n    注：本商城已迁移至新版商城，点击上方按钮代表同意并访问新版商城\n  "
      ),
    ]),
    _c("p", { staticClass: "content", on: { click: _vm.closeWin } }, [
      _vm._v("不访问点此关闭"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }