<!--  -->
<template>
  <div class="not-found">
    <div class="banner">
      <img :src="img" alt="" />
    </div>
    <div class="button_btn" @click="link">立即跳转</div>
    <p class="titel">
      注：本商城已迁移至新版商城，点击上方按钮代表同意并访问新版商城
    </p>
    <p class="content" @click="closeWin">不访问点此关闭</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../../static/image/banner.png"),
      url: "",
    };
  },
  created() {
    let params = new URLSearchParams(location.search);
    console.log("[ params ] >", params);
    let [url] = [params.get("v2_url")];
    this.url = url;
    console.log("[ this.url ] >", this.url);
    console.log("[ decodeURIComponent ] >", decodeURIComponent(this.url));
  },
  methods: {
    closeWin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("是微信浏览器");
        WeixinJSBridge.call("closeWindow");
      } else {
        console.log("不是微信浏览器");
        window.close();
      }
    },
    link() {
      window.location.replace(decodeURIComponent(this.url));
    },
  },
};
</script>
<style lang="less" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: url(../../static/image/bg.png) no-repeat;
  background-size: 100% 100%;
  color: #333;
  align-items: center;
  .button_btn {
    width: 50%;
    height: 40px;
    border-radius: 25px;
    background: url(../../static/image/btn_back.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;
  }
  .banner {
    width: 300px;
    height: 230px;
    img {
      width: 100%;
    }
    margin-top: 25%;
  }
  .titel {
    margin-top: 20px;
    width: 80%;
    font-size: 14px;
    line-height: 17px;
    color: #999;
  }
  .content {
    width: 80%;
    font-size: 12px;
    margin-top: 20px;
    height: 30px;
    text-decoration: underline;
    color: #f95d37;
  }
}
</style>
